import { MetaDefinition } from '@angular/platform-browser';
import { IContactInfo, IEnvironment } from './settings-interface';

export const environment: IEnvironment = {
  environment: '#{environment}#'.toLowerCase(),
  isTest: '#{environment}#'.toLowerCase() !== 'production',
  apiTarget: 'https://#{hostName}#',
  apiKey: '#{apiKey}#',
  saveContactInformation: false,
  hostName: '#{hostName}#',
  googleMaps: '#{googleMaps}#',
  appInsights: {
    config: {
      instrumentationKey: '#{INSTRUMENTATIONKEY}#',
    },
    roleName: '#{appInsights.roleName}#',
  },
  contactForm: {
    baseUrl: '#{contactForm.baseUrl}#',
    endpoint: '#{contactForm.endpoint}#',
    subject: '#{contactForm.subject}#',
    redirectToPage: '#{contactForm.redirectTo}#',
  },
  logging: {
    enabled: '#{loggingEnabled}#'.toLowerCase() === 'true',
    consoleEnabled: '#{consoleLoggingEnabled}#'.toLowerCase() === 'true',
    debug: false,
  },
  metaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
    {
      name: 'mobile-web-app-capable',
      content: 'yes',
    },
    {
      name: 'theme-color',
      content: '#b6c105',
    },
    {
      name: 'description',
      content: '',
    },
    {
      name: 'robots',
      content: '#{robots}#',
    },
    {
      name: 'p:domain_verify',
      content: '#{pinterestVerification}#',
    },
  ] as MetaDefinition[],
  contactInfo: {
    name: '#{contactInfo.name}#',
    streetAddress: '#{contactInfo.streetAddress}#',
    city: '#{contactInfo.city}#',
    postalCode: '#{contactInfo.postalCode}#',
    phone: '#{contactInfo.phone}#',
    email: '#{contactInfo.email}#',
    companyName: '#{contactInfo.companyName}#',
    orgNr: '#{contactInfo.orgNr}#',
  } as IContactInfo,
  cookies: {
    cookieName: '#{cookies.cookieName}#',
    cartCookieName: '#{cookies.cartCookieName}#',
  },
  useClarity: '#{useClarity}#'.toLowerCase() === 'true',
  services: [
    {
      name: 'SERVICES.SERVICES.0.NAME',
      price: 750,
      vat: 0,
      linkItem: {
        link: 'https://www.bokadirekt.se/boka-tjanst/fotverket-46926/medicinsk-fotvard-1833723',
        text: 'SERIVCES.BOOK',
      },
      description: 'SERVICES.SERVICES.0.DESCRIPTION',
    },
    {
      name: 'SERVICES.SERVICES.1.NAME',
      price: 752,
      vat: 0.25,
      linkItem: {
        link: 'https://www.bokadirekt.se/boka-tjanst/fotverket-46926/fotvard-friskvard-1833875',
        text: 'SERIVCES.BOOK',
      },
      description: 'SERVICES.SERVICES.1.DESCRIPTION',
    },
    {
      name: 'SERVICES.SERVICES.2.NAME',
      price: 300,
      vat: 0,
      linkItem: {
        link: 'https://www.bokadirekt.se/boka-tjanst/fotverket-46926/delbehandling-medicinsk-fotvard-1833725',
        text: 'SERIVCES.BOOK',
      },
      description: 'SERVICES.SERVICES.2.DESCRIPTION',
    },
    {
      name: 'SERVICES.SERVICES.3.NAME',
      price: 400,
      vat: 0,
      linkItem: {
        link: 'https://www.bokadirekt.se/boka-tjanst/fotverket-46926/klippning-naglar-1833866',
        text: 'SERIVCES.BOOK',
      },
      description: 'SERVICES.SERVICES.3.DESCRIPTION',
    },
  ],
  googleTagManagerId: '#{googleTagManagerId}#',
};
